import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'qr-video-container',
  templateUrl: './qr-video-container.component.html',
  styleUrls: ['./qr-video-container.component.scss'],
  standalone: true,
})
export class QrVideoContainerComponent implements OnChanges {
  @Input() video: string = '';
  @Input() title: string = '';
  // url: any;
  videoURLsanitizer: string = '';

  constructor(private domSanitize: DomSanitizer) {}

  /*
	ngOnChanges: Detecta que hubo un cambio en el componente
	*/
  ngOnChanges(): void {
    if (this.video) {
      this.videoURLsanitizer =
        'https://www.youtube.com/embed/' + this.youTubeGetID(this.video);
    }
  }

  sanitizeURL(url: any) {
    return this.domSanitize.bypassSecurityTrustResourceUrl(url);
  }

  youTubeGetID(url: any): string {
    let ID = '';
    url = url
      .replace(/(>|<)/gi, '')
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_-]/i);
      ID = ID[0];
    } else {
      ID = url;
    }
    return ID;
  }
}
